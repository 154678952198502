import { render, staticRenderFns } from "./HumaniaFeed.vue?vue&type=template&id=b536a926&scoped=true"
import script from "./HumaniaFeed.vue?vue&type=script&lang=js"
export * from "./HumaniaFeed.vue?vue&type=script&lang=js"
import style0 from "./HumaniaFeed.vue?vue&type=style&index=0&id=b536a926&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b536a926",
  null
  
)

export default component.exports